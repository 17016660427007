<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                             <b-row>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <ValidationProvider name="Agriculture Material Type" vid="material_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="material_type_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('dae_config.agriculture_material_type') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="aMaterialSetup.material_type_id"
                                                    :options="agMaterialTypeList"
                                                    id="material_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <ValidationProvider name="Name (En)" vid="material_name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="material_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_config.agriculture_material_name_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="material_name"
                                                    v-model="aMaterialSetup.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <ValidationProvider name="Name (Bn)" vid="material_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="material_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_config.agriculture_material_name_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="material_name_bn"
                                                    v-model="aMaterialSetup.name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <ValidationProvider name="Price" vid="price">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="price"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_config.price') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="price"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    v-model="aMaterialSetup.price"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { aggriMaterialSetupStore, aggriMaterialSetupUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getaMaterialSetupData()
            this.aMaterialSetup = Object.assign(tmp, { materialSetupDetail: tmp.material_setup_detail })
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            aMaterialSetup: {
                material_type_id: '0',
                name: '',
                name_bn: '',
                price: ''
            }
        }
    },
    mounted () {
        // core.index()
    },
    computed: {
        agMaterialTypeList: function () {
            const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    },
    methods: {
        addNew () {
            const singleRow = {
                name: '',
                name_bn: '',
                price: ''
            }
            this.aMaterialSetup.materialSetupDetail.push(singleRow)
        },
        remove (index) {
            this.aMaterialSetup.materialSetupDetail.splice(index, 1)
        },
        getaMaterialSetupData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${aggriMaterialSetupUpdate}/${this.id}`, this.aMaterialSetup)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, aggriMaterialSetupStore, this.aMaterialSetup)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
